exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-branding-js": () => import("./../../../src/pages/features/branding.js" /* webpackChunkName: "component---src-pages-features-branding-js" */),
  "component---src-pages-features-colleagues-js": () => import("./../../../src/pages/features/colleagues.js" /* webpackChunkName: "component---src-pages-features-colleagues-js" */),
  "component---src-pages-features-inform-js": () => import("./../../../src/pages/features/inform.js" /* webpackChunkName: "component---src-pages-features-inform-js" */),
  "component---src-pages-features-onboard-js": () => import("./../../../src/pages/features/onboard.js" /* webpackChunkName: "component---src-pages-features-onboard-js" */),
  "component---src-pages-features-scheduling-js": () => import("./../../../src/pages/features/scheduling.js" /* webpackChunkName: "component---src-pages-features-scheduling-js" */),
  "component---src-pages-features-settings-js": () => import("./../../../src/pages/features/settings.js" /* webpackChunkName: "component---src-pages-features-settings-js" */),
  "component---src-pages-features-talk-js": () => import("./../../../src/pages/features/talk.js" /* webpackChunkName: "component---src-pages-features-talk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

