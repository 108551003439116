import './src/styles/global.scss'
import React, { useEffect } from 'react';


export default ({ element }) => {


  return (<div>{element}</div>)


}